import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {

  message_text: string = "";
  message_class: string = "";

  public registerForm: FormGroup;
  private submitted = false;
  public menuOn = false;
  private menuOnNotHashes = ['inicio'];

  @ViewChild('inicio') inicio: ElementRef;

  constructor(private httpClient: HttpClient, private formBuilder: FormBuilder) {}

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      company: ['', Validators.required],
      phone: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  @HostListener('window:hashchange', ['$event'])
  onScroll($event: Event) :void {
    if (window.innerWidth > 700) {
      let hash = window.location.hash.slice(1);
      this.menuOn = this.menuOnNotHashes.findIndex((element) => element == hash) < 0;
    }
  };

  sendMail() {
    
    if (this.registerForm.invalid) {
      this.message_text = "Por favor ingrese los datos correctamente";
      this.message_class = "alert-danger";
      return;
    }

    this.message_text = "";
    this.message_class = "";

    // let url = '/contact';
    let url = '/contact/index.php';

    let data = {
      'name': this.registerForm.controls.name.value,
      'email': this.registerForm.controls.email.value,
      'company': this.registerForm.controls.company.value,
      'phone': this.registerForm.controls.phone.value,
      'message': this.registerForm.controls.message.value
    }

    if (!this.submitted) {
      this.submitted = true;
      this.httpClient.post(url, data).subscribe((response: any) => {
        if (response && response.code === 200) {
          this.message_text = "Mensaje enviado correctamente!.";
          this.message_class = "alert-success";
        } else {
          this.message_text = "Ha ocurrido un problema, por favor intente más tarde."
          this.message_class = "alert-danger";
        }
        this.registerForm.reset();
        this.submitted = false;
      });
    }
  }
}
